import { CacheLocation } from "@auth0/auth0-react";
import { AppError } from "esa-core";

export type Auth0Config = Readonly<{
  domain: string;
  clientId: string;
  cacheLocation: CacheLocation;
}>;

export type TwilioConfig = Readonly<{
  phoneVerificationNumber: string;
}>;

export type MasterConfig = Readonly<{
  audience: string;
  url: string;
}>;

export type ApiConfig = Readonly<{
  audience: string;
  url: string;
}>;

export type AppConfig = Readonly<{
  url: string;
  version: string;
}>;

export type EboneConfig = Readonly<{
  maintenanceUrl: string;
  scheduleUrl: string;
  apiUrl: string;
}>;

export type EsaConfig = Readonly<{
  maintenanceUrl: string;
  scheduleUrl: string;
}>;

export type SupportAnnounceConfig = Readonly<{
  url: string;
}>;

export type LoggerConfig = Readonly<{
  enabled: boolean;
}>;

export type FeatureFlagConfig = Readonly<{
  oneInterlocking: boolean;
}>;

export type FileConfig = Readonly<{
  bytesLimit: number;
}>;

export type ExternalServiceConfig = Readonly<{
  EbSquare: string;
  sumaiEntry: string;
  EbRent: string;
  EbSfa: string;
}>;

export type StatusPageConfig = Readonly<{
  url: string;
  api: string;
  pageId: string;
  esaId: string;
}>;

export type TakkenConfig = Readonly<{
  audience: string;
  url: string;
}>;

export type MfaConfig = Readonly<{
  enabled: boolean;
}>;

export type Config = Readonly<{
  auth0: Auth0Config;
  twilio: TwilioConfig;
  master: MasterConfig;
  api: ApiConfig;
  app: AppConfig;
  ebone: EboneConfig;
  esa: EsaConfig;
  supportAnnounce: SupportAnnounceConfig;
  logger: LoggerConfig;
  featureFlag: FeatureFlagConfig;
  file: FileConfig;
  externalService: ExternalServiceConfig;
  statusPage: StatusPageConfig;
  takken: TakkenConfig;
  mfa: MfaConfig;
}>;

const readEnv = (key: string): string => {
  const env = import.meta.env[key];
  if (typeof env !== "string") {
    throw new AppError("Not found environment variable", { key });
  }
  return env;
};

const cacheLocationFromEnv = (): CacheLocation => {
  try {
    const env = readEnv("VITE_APP_CACHE_LOCATION");
    switch (env) {
      case "localstorage":
      case "memory":
        return env;
      default:
        console.error("Unexpected cache location", {
          env,
        });
        return "memory";
    }
  } catch (e) {
    console.error("Unexpected error when read cache location", { e });
    return "memory";
  }
};

export const getConfig = (): Config => ({
  auth0: {
    domain: readEnv("VITE_AUTH0_DOMAIN"),
    clientId: readEnv("VITE_AUTH0_CLIENT_ID"),
    cacheLocation: cacheLocationFromEnv(),
  },
  twilio: {
    phoneVerificationNumber: readEnv("VITE_TWILIO_PHONE_VERIFICATION_NUMBER"),
  },
  master: {
    audience: readEnv("VITE_MASTER_AUDIENCE"),
    url: readEnv("VITE_MASTER_URL"),
  },
  api: {
    audience: readEnv("VITE_API_AUDIENCE"),
    url: readEnv("VITE_API_URL"),
  },
  app: {
    url: readEnv("VITE_APP_URL"),
    version: import.meta.env.APP_VERSION,
  },
  ebone: {
    maintenanceUrl: readEnv("VITE_EBONE_MAINTENANCE_URL"),
    scheduleUrl: readEnv("VITE_EBONE_SCHEDULE_URL"),
    apiUrl: readEnv("VITE_EBONE_API_BASE_URL"),
  },
  esa: {
    maintenanceUrl: readEnv("VITE_ESA_MAINTENANCE_URL"),
    scheduleUrl: readEnv("VITE_ESA_SCHEDULE_URL"),
  },
  supportAnnounce: {
    url: readEnv("VITE_SUPPORT_ANNOUNCE_URL"),
  },
  logger: {
    enabled: readEnv("VITE_LOGGER_ENABLED") === "true",
  },
  featureFlag: {
    oneInterlocking: readEnv("VITE_FEATURE_ONE_INTERLOCKING") === "true",
  },
  file: {
    bytesLimit: parseInt(readEnv("VITE_FILE_BYTES_LIMIT"), 10),
  },
  externalService: {
    EbSquare: readEnv("VITE_SQUARE_URL"),
    sumaiEntry: readEnv("VITE_SUMAI_ENTRY_URL"),
    EbRent: readEnv("VITE_RENT_URL"),
    EbSfa: readEnv("VITE_SFA_URL"),
  },
  statusPage: {
    url: readEnv("VITE_STATUS_PAGE_URL"),
    api: readEnv("VITE_STATUS_PAGE_API_URL"),
    pageId: readEnv("VITE_STATUS_PAGE_ID"),
    esaId: readEnv("VITE_STATUS_PAGE_ESA_ID"),
  },
  takken: {
    audience: readEnv("VITE_TAKKEN_AUDIENCE"),
    url: readEnv("VITE_TAKKEN_URL"),
  },
  mfa: {
    enabled: readEnv("VITE_MFA_ENABLED") === "true",
  },
});
